
import { post } from '@/utils/request';

// 会员等级列表
const getLevelList = (data) => post({
    url: '/store/common.Select/getClientLevelList',
    data: data
});

// 获取积分规则-列表
const integGetList = (data) => post({
    url: '/store/customer.InegralRule/lists',
    data: data
})

// 获取积分规则-新增
const integGetAdd = (data) => post({
    url: '/store/customer.InegralRule/create',
    data: data
})

// 获取积分规则-修改
const integGetUpdate = (data) => post({
    url: '/store/customer.InegralRule/update',
    data: data
})

// 获取积分规则-删除
const integGetDel = (data) => post({
    url: '/store/customer.InegralRule/delData',
    data: data
})

// 使用积分规则-列表
const integUseList = (data) => post({
    url: '/store/customer.UseIntegralRule/lists',
    data: data
})

// 使用积分规则-新增
const integUseCreate = (data) => post({
    url: '/store/customer.UseIntegralRule/create',
    data: data
})

// 使用积分规则-修改
const integUseUpdate = (data) => post({
    url: '/store/customer.UseIntegralRule/update',
    data: data
})

// 使用积分规则-删除
const integUseDel = (data) => post({
    url: '/store/customer.UseIntegralRule/delData',
    data: data
})

// 公众号设置-列表
const weChatSetList = (data) => post({
    url: '/store/customer.WeChatSet/lists',
    data: data
})

// 公众号设置-新增
const weChatSetAdd = (data) => post({
    url: '/store/customer.WeChatSet/create',
    data: data
})

// 公众号设置-更新
const weChatSetUpdate = (data) => post({
    url: '/store/customer.WeChatSet/update',
    data: data
})

// 公众号设置-删除
const weChatSetDel = (data) => post({
    url: '/store/customer.WeChatSet/delData',
    data: data
})

// 会员等级-列表
const userLevel = (data) => post({
    url: '/store/customer.UserLevel/lists',
    data: data
})

// 会员等级-新增
const userLevelCreate = (data) => post({
    url: '/store/customer.UserLevel/create',
    data: data
})

// 会员等级-修改
const userLevelUpdate = (data) => post({
    url: '/store/customer.UserLevel/update',
    data: data
})

// 会员等级-删除
const userLevelDel = (data) => post({
    url: '/store/customer.UserLevel/delData',
    data: data
})

// 会员特权-列表
const privList = (data) => post({
    url: '/store/common.User/privList',
    data: data
})

// 会员等级-享受折扣
const userLevelDetails = (data) => post({
    url: '/store/customer.UserLevel/details',
    data: data
})

// 会员等级-享受折扣删除
const delDiscount = (data) => post({
    url: '/store/customer.UserLevel/delDiscount',
    data: data
})

export {
    getLevelList,
    integGetList,
    integGetAdd,
    integGetUpdate,
    integGetDel,
    integUseList,
    integUseCreate,
    integUseUpdate,
    integUseDel,
    weChatSetList,
    weChatSetAdd,
    weChatSetUpdate,
    weChatSetDel,
    userLevel,
    userLevelCreate,
    userLevelUpdate,
    userLevelDel,
    privList,
    userLevelDetails,
    delDiscount
}